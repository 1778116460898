<template>
  <div class="layout">
    <Header :hasSearch="false" :hasShadow="true" />
    <div class="container mt-80 mb-20 pb-20">
      <el-card class="mt-20" shadow="hover" :body-style="{ padding: '20px' }">
        <div
          class="d-flex justify-content-between align-items-center"
          slot="header"
        >
          <h5>{{ $route.meta.title }}</h5>
          <div class="d-flex justify-content-end align-items-center">
            <el-input placeholder="关键词搜索" v-model="params.keyword">
            </el-input>
          </div>
        </div>

        <router-link
          v-for="item in lists"
          :key="'item' + item.id"
          :to="{ path: '/org/detail/' + item.id }"
          class="block flex-fill"
          target="_blank"
        >
          <img v-if="item.logo" :src="viewImg(item.logo)" class="thumb" />

          <div class="block-body">
            <div class="block-body-title">
              {{ item.name }}
            </div>

            <div class="block-body-desc">
              {{ item.description }}
            </div>
            <div class="block-body-desc">
              <span> {{ item.createDate }} </span>
              <span> {{ $utils.contrastTime(item.create_time) }}</span>
            </div>
          </div>
        </router-link>

        <div class="text-center mt-20" v-if="params.pages > 1">
          <el-pagination
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="params.page"
            :page-size="params.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="params.total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Header from "../layout/Header.vue";
export default {
  name: "List",
  components: {
    Header,
  },
  data() {
    return {
      metaTitle: "",
      loading: false,
      lists: [],
      searchTimer: null, //搜索计时器
      params: {
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  metaInfo() {
    return {
      title: this.metaTitle + "机构-" + this.Settings.base.name,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.organization.Organization.lists(this.params)
        .then((res) => {
          console.log("res:", res);
          this.loading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch((err) => {
          console.log("err:", err);
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
}
</style>